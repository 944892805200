import React, { useEffect, useState } from 'react';
import { checkTokenStatus, connectAccount, removeToken } from '../api/authService';

const MainPage: React.FC = () => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  useEffect(() => {
    checkTokenStatus()
      .then(isConnected => setIsConnected(isConnected))
      .catch((error) => console.error('Error checking token status:', error));
  }, []);

  const handleRemoveToken = async () => {
    try {
      await removeToken();
      setIsConnected(false);
    } catch (error) {
      console.error('Error removing token:', error);
    }
  };

  const handleConnectAccount = () => {
    connectAccount(setIsConnected);
  };

  return (
    <div>
      <h1>Outlook Integration</h1>
      {isConnected ? (
        <div>
          <p>Status: Connected</p>
          <button onClick={handleRemoveToken}>Remove Connection</button>
          <button onClick={handleConnectAccount}>Refresh Connection</button>
        </div>
      ) : (
        <div>
          <p>Status: Not Connected</p>
          <button onClick={handleConnectAccount}>Connect Account</button>
        </div>
      )}
    </div>
  );
};

export default MainPage;
